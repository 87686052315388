var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{staticStyle:{"background-color":"var(--v-background-base) !important"},attrs:{"flat":""}},[_c('v-toolbar',{attrs:{"color":"greyBase","dark":"","extended":!_vm.$vuetify.breakpoint.mobile,"flat":"","extension-height":"55"}},[_c('v-toolbar-title',[_c('b',{staticClass:"mr-3"},[_vm._v("Intermodal")])]),_c('v-spacer'),_c('v-chip',{staticClass:"pr-2",attrs:{"icon":"","outlined":"","color":_vm.iconColor}},[_c('span',{staticClass:"pr-2",staticStyle:{"color":"var(--v-primaryText-base)"}},[_vm._v("Navis")]),_c('v-icon',{class:{ 'flicker-icon': _vm.flicker },attrs:{"small":"","color":_vm.iconColor}},[_vm._v("radio_button_checked")])],1),_c('v-btn-toggle',{attrs:{"mandatory":"","outlined":"","light":!_vm.$store.state.darkMode,"rounded":"","color":"blue"},model:{value:(_vm.toggleView),callback:function ($$v) {_vm.toggleView=$$v},expression:"toggleView"}},[_c('v-btn',{attrs:{"small":"","outlined":""}},[_c('v-icon',{attrs:{"left":"","small":"","color":_vm.toggleView == 0 ? 'blue' : 'primaryText'}},[_vm._v("local_shipping")]),_c('span',{staticStyle:{"text-transform":"none","color":"var(--v-primaryText-base)"}},[_vm._v("Loads")])],1)],1)],1),_c('v-card',{staticClass:"mx-auto",style:({
        'margin-top': !_vm.$vuetify.breakpoint.mobile ? '-64px' : '15px',
        'border-radius': !_vm.$vuetify.breakpoint.mobile ? '50px' : '0px',
      }),attrs:{"max-width":!_vm.$vuetify.breakpoint.mobile ? '94vw' : '100%'}},[_c('v-card-text',{style:({
          height: !_vm.$vuetify.breakpoint.mobile ? '90vh' : '90vh',
          'overflow-y': 'auto',
        })},[_c('v-expand-transition',[(_vm.toggleView == 0)?_c('div',[_c('ManageTransport')],1):_vm._e()])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }